function Margaux({imgsrc}){
    return(
        <div>
            <h2>Hello, Margaux!</h2>
            <img alt="camera" style={{maxWidth:"80%", display: "block", margin:"0 auto", padding: 0}} src={imgsrc}/>
            <p>Please tell your Ate Pash I love her lots! 😊</p>
            <p>Just let me know if there's a new game you want to play. You can tell Patti or you could also just add it to your wishlist.</p>
            <p>Pasta luego! 🍝🍝🍝</p>
        </div>
    )
}
export default Margaux