import Webcam from "react-webcam"
import React, {useRef, useCallback} from 'react'

function CameraView({setImgsrc}){
    const camRef = useRef(null)
    const capture = useCallback(
        (e)=>{
            e.preventDefault()
            setImgsrc(camRef.current.getScreenshot())
        },
        [camRef,setImgsrc]
    )
    return(
        <div style={{width: "100%"}}>
            <h2>🔒Take a selfie to unlock </h2>
            <Webcam style={{"maxWidth": "100%"}} ref={camRef}/>
            <div>
                <button className="CameraButton" href="#" onClick={capture}></button>
            </div>
        </div>
    )
    }
export default CameraView