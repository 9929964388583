function Patti({imgsrc}){
    return(
        <div>
            <h2>Pretty and MORE...</h2>
            <img alt="camera" style={{maxWidth:"80%", display: "block", margin:"0 auto", padding: 0}} src={imgsrc}/>
            <p>and I never felt more in love!!</p>
            <p>Thanks for waking me up from denial and for letting me experience all this.</p>
            <p> I love you so, so much, Patti! 💖</p>
            <p>You're the best!</p>
        </div>
    )
}
export default Patti