function Impostor({imgsrc, results}){
    const labels = results.map(r=>r.label)
    const impostorCount = labels.includes("patti") ? results.length-1 : results.length
    return(
        <div>
            <h2>Impostor!</h2>
            <img alt="camera" style={{maxWidth:"80%", display: "block", margin:"0 auto", padding: 0}} src={imgsrc}/>
            <p>There {impostorCount>1 ? "are": "is" } <span className="redCount">{impostorCount}</span> impostor{impostorCount>1 ? "s": "" } among us.</p>
        </div>
    )
}
export default Impostor