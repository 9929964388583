import Unknown from './Unknown'
import Patti from './Patti'
import Impostor from './Impostor'
import Margaux from './Margaux'
import Louise from './Louise'
import Yosh from './Yosh'
function getPage(results){
    if(results.length===1 && results[0].label==="patti"){
        return Patti
    }else if(results.length===1 && results[0].label==="margaux"){
        return Margaux
    }else if(results.length===1 && results[0].label==="louise"){
        return Louise
    }else if(results.length===1 && results[0].label==="yosh"){
        return Yosh
    }else if(results.length===1 && results[0].label==="unknown"){
        return Unknown
    }else if(results.length===0){
        return Unknown
    }else if(results.length>1){
        return Impostor
    }
}
function Results({results, setShowResults, imgsrc}){
    const Page = getPage(results)
    return(
        <div>
            <Page imgsrc={imgsrc} results={results}/>
            <button onClick={()=>setShowResults(false)}>Go Back</button>
        </div>
    )
}
export default Results