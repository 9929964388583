import './App.css';
import React, {useState, useEffect} from 'react'
import * as faceapi from 'face-api.js'
import CameraView from "./CameraView"
import Results from './Results';
/** 
 function loadLabeledImages() {
   const labels =["margaux", "patti", "yosh", "louise"]
   const imgCount = {
     margaux: 2,
     patti: 6,
     yosh: 1,
     louise: 2
   }
   return Promise.all(
     labels.map(async label =>{
       const descriptions = []
       for (let i = 1; i <= imgCount[label]; i++) {
         const img = await faceapi.fetchImage(`/img/${label}/${i}.jpg`)
         const detections = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor()
         descriptions.push(detections.descriptor)
       }
       return new faceapi.LabeledFaceDescriptors(label, descriptions)
     })
     )
   }
  * **/
  const evalMatch = async (imgsrc, setResults, faceMatcher, setShowResults)=>{
    const image = await faceapi.fetchImage(imgsrc)
    const detections = await faceapi.detectAllFaces(image).withFaceLandmarks().withFaceDescriptors()
    const results = detections.map(d=>{
      return faceMatcher.findBestMatch(d.descriptor)
    });
    setResults(results)
    setShowResults(true)
    console.log(results)

  }
  function App() {
    const [modelLoaded, setModelLoaded] = useState(false)
    const [imgsrc, setImgsrc] = useState('');
    const [faceMatcher, setFaceMatcher] = useState({})
    const [showResults, setShowResults] = useState(false)
    const [results, setResults] = useState([])

    useEffect(()=>{
      //console.log(imgsrc)
      if (imgsrc.length!==0){
        evalMatch(imgsrc, setResults, faceMatcher, setShowResults)
      }
    },[imgsrc, faceMatcher, setResults, setShowResults])    
    useEffect(() => {
      setModelLoaded(false)
      Promise.all([
        faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
        faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
        faceapi.nets.ssdMobilenetv1.loadFromUri('/models')
      ]).then(start)
      async function start(){
        console.log("loading")
        //const labeledFaceDescriptors = await loadLabeledImages()
        //const faceMatchertemp = new faceapi.FaceMatcher(labeledFaceDescriptors, 0.5)
        const resp = await fetch('/matcher/matcher.json')
        const matcherobj = await resp.json()
        const faceMatcher = faceapi.FaceMatcher.fromJSON(matcherobj)
        setFaceMatcher(faceMatcher)
        console.log("loaded")
        setModelLoaded(true)
      }
  },[setFaceMatcher])
  return (
    <div className="App">
      <header className="App-header">
        {showResults ? (<Results results={results} imgsrc={imgsrc} setShowResults={setShowResults}/>) : modelLoaded ? <CameraView setImgsrc={setImgsrc}/> : (<div>
          <p className="LogoMoon">🌝</p>
          <p>loading...</p>
        </div>)}
        {
        }
        
      </header>
    </div>
  );
}

export default App;
